import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './uploadDocuments.actions';
import {selectUploadDocumentsData} from './uploadDocuments.selectors';
import {UiActions} from '../../../../../features/ui/store/ui.action';
import {ModalsKeys} from '../../../../../features/ui/utils/constants';
import {Toast} from '../../../../../lib/toast';
import {
    DOCUMENT_CONTEXTS,
    DOCUMENT_TYPES,
    UPLOAD_DOCUMENTS_STEPS,
} from '../../../../config/constants/documentConstants';
import {
    uploadCompanyBalanceSheetRequest,
    uploadCompanyDocumentRequest,
} from '../../../api/providers/company/company.provider';
import {safe} from '../../../safeSaga';
import {getCompanyId} from '../../../utils/get-company-id';
import {getFreelancerId} from '../../../utils/get-freelancer-id';
import {getCompanyDocuments} from '../companyDocuments.actions';

const uploadDocumentSaga = function* (companyId, isReplace, file, type, category, id, subType, year) {
    try {
        if (type === DOCUMENT_TYPES.BALANCE_SHEET) {
            yield call(uploadCompanyBalanceSheetRequest, companyId, file, type, category, id, year);
        } else {
            yield call(uploadCompanyDocumentRequest, companyId, file, type, category, id, subType, year);
        }

        if (isReplace) {
            Toast.success('fileReplacedSuccessfully', {
                translationValues: {
                    fileName: file.name,
                },
            });

            return;
        }

        Toast.success('fileUploadedSuccessfully', {
            translationValues: {
                fileName: file.name,
            },
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // KBIS UPLOAD 409 - This is covered on three separate places, use this comment content to find them in code
        if (error?.response?.status === 409) {
            if (error?.response.data?.error === 'Could not handle Kbis document upload, company name missing') {
                Toast.warning('kbisUploadCompanyNameMissing');
                return;
            }
            if (error?.response.data?.error === 'Could not handle Kbis document upload, activityStartDate missing') {
                Toast.warning('kbisUploadStartDateMissing');
                return;
            }
            if (error?.response.data?.error === 'Kbis document can not be uploaded until company reaches REGISTRATION_COMPLETED status during onboarding.') {
                Toast.warning('kbisUploadWrongCompanyStatus');
                return;
            }
        }

        if (error?.response?.status === 413) {
            Toast.error('fileTooLarge');

            return;
        }

        throw error;
    }
};

const uploadDocumentsSaga = function* (companyId, isReplace) {
    const data = yield select(selectUploadDocumentsData);

    yield all(data.map(item => {
        return call(
            uploadDocumentSaga,
            companyId,
            isReplace,
            item.file,
            item.type,
            item.category,
            item.id || undefined,
            item.subType || undefined,
            item.year || undefined,
        );
    }));
};

const uploadDocumentsWorkerSaga = function* ({payload}) {
    yield put(actions.setIsUploadingDocuments(true));

    let {freelancerId, companyId, ...rest} = payload;

    if (!freelancerId) {
        freelancerId = yield call(getFreelancerId);
    }

    if (!companyId) {
        companyId = yield call(getCompanyId);
    }

    try {
        yield call(uploadDocumentsSaga, companyId, rest?.isReplace);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }

    yield put(getCompanyDocuments(freelancerId, companyId, DOCUMENT_CONTEXTS.DATABASE));

    yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_DOCUMENTS, false));
    yield put(actions.storeUploadDocumentsData([]));
    yield put(actions.setUploadDocumentsStep(UPLOAD_DOCUMENTS_STEPS.ATTACH_DOCUMENTS));
    yield put(actions.setIsUploadingDocuments(false));
};

export const watchUploadDocumentsSaga = function* () {
    yield all([
        takeLatest(actions.UPLOAD_DOCUMENTS, safe(uploadDocumentsWorkerSaga)),
    ]);
};
