const prefix = 'dashboard-v3';

const FETCH_DATA = `${prefix}/FETCH_DATA`;
const STORE_DATA = `${prefix}/STORE_DATA`;
const REQUEST_REMUNERATION_CALCULATION = `${prefix}/REQUEST_REMUNERATION_CALCULATION`;
const SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN = `${prefix}/SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN`;
const INCOME_TAX_GENERATE_SIMULATION = `${prefix}/INCOME_TAX_GENERATE_SIMULATION`;
const STORE_INCOME_TAX_SIMULATION = `${prefix}/STORE_INCOME_TAX_SIMULATION`;
const GET_LATEST_INCOME_TAX_SIMULATION = `${prefix}/GET_LATEST_INCOME_TAX_SIMULATION`;

export const DashboardV3ActionTypes = {
    FETCH_DATA,
    STORE_DATA,
    REQUEST_REMUNERATION_CALCULATION,
    SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
    INCOME_TAX_GENERATE_SIMULATION,
    STORE_INCOME_TAX_SIMULATION,
    GET_LATEST_INCOME_TAX_SIMULATION,
};
