import {DashboardV3ActionTypes} from './dashboard-v3.action-type';

const {
    FETCH_DATA,
    STORE_DATA,
    REQUEST_REMUNERATION_CALCULATION,
    SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
    INCOME_TAX_GENERATE_SIMULATION,
    STORE_INCOME_TAX_SIMULATION,
    GET_LATEST_INCOME_TAX_SIMULATION,
} = DashboardV3ActionTypes;

const fetchData = () => ({
    type: FETCH_DATA,
});

const storeData = payload => ({
    type: STORE_DATA,
    payload,
});

const requestRemunerationCalculation = payload => ({
    type: REQUEST_REMUNERATION_CALCULATION,
    payload,
});

const setIsRemunerationInstantTransferOpen = payload => ({
    type: SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
    payload,
});

const generateIncomeTaxSimulation = payload => ({
    type: INCOME_TAX_GENERATE_SIMULATION,
    payload,
});

const storeIncomeTaxSimulation = payload => ({
    type: STORE_INCOME_TAX_SIMULATION,
    payload,
});

const getLatestIncomeTaxSimulation = payload => ({
    type: GET_LATEST_INCOME_TAX_SIMULATION,
    payload,
});

export const DashboardV3Actions = {
    fetchData,
    storeData,
    requestRemunerationCalculation,
    setIsRemunerationInstantTransferOpen,
    generateIncomeTaxSimulation,
    storeIncomeTaxSimulation,
    getLatestIncomeTaxSimulation,
};
