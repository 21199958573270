import {combineReducers} from 'redux';
import {DashboardV3ActionTypes} from './dashboard-v3.action-type';
import {createReducer} from '../../../utils/create-reducer';

export const dashboardV3Reducer = combineReducers({
    data: createReducer(null, DashboardV3ActionTypes.STORE_DATA),
    isRemunerationInstantTransferOpen: createReducer(
        false,
        DashboardV3ActionTypes.SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
    ),
    incomeTaxSimulation: createReducer({}, DashboardV3ActionTypes.STORE_INCOME_TAX_SIMULATION),
});
