import moment from 'moment';
import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {BackofficeActions} from './backoffice.action';
import {BackofficeActionTypes} from './backoffice.action-type';
import {BackofficeSelector} from './backoffice.selector';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {getCompanyRequest, uploadCompanyDocumentRequest} from '../../../v1/app/api/providers/company/company.provider';
import {storeCompany} from '../../../v1/app/company/company.actions';
import * as actions from '../../../v1/app/company/company.actions';
import {FULL_COMPANY_STATUSES} from '../../../v1/app/company/setupCompany/setupCompany.constants';
import {DOCUMENT_TYPES} from '../../../v1/config/constants/documentConstants';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../loading';
import {SidebarSelectors} from '../../sidebar/store/sidebar.selector';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {UserApi} from '../../user/api/user.api';
import {BackofficeApi} from '../api/backoffice.api';
import {PAGE_OPTIONS, STAGES} from '../utils/constants';

// TODO This should be removed, added to solve dependancy cycle
const uploadDocumentFlow = function* ({companyId, file, type, category, id, subType}) {
    try {
        yield call(uploadCompanyDocumentRequest, companyId, file, type, category, id, subType);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // KBIS UPLOAD 409 - This is covered on three separate places, use this comment content to find them in code
        if (error?.response?.status === 409) {
            if (error?.response.data?.error === 'Could not handle Kbis document upload, company name missing') {
                Toast.warning('kbisUploadCompanyNameMissing');
                return;
            }
            if (error?.response.data?.error === 'Could not handle Kbis document upload, activityStartDate missing') {
                Toast.warning('kbisUploadStartDateMissing');
                return;
            }
            if (error?.response.data?.error === 'Kbis document can not be uploaded until company reaches REGISTRATION_COMPLETED status during onboarding.') {
                Toast.warning('kbisUploadWrongCompanyStatus');
                return;
            }
        }

        if (error?.response?.status === 413) {
            // TODO Handle file too large

            return;
        }

        throw error;
    }
};

const getCompanySaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingCompany(true));

        const {companyId, freelancerId} = payload;

        const company = yield call(getCompanyRequest, freelancerId, companyId);

        yield put(actions.storeCompany(company));

        return company;
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingCompany(false));
    }
};

export const updateCompaniesFlow = function* () {
    try {
        const selectedRole = yield select(SidebarSelectors.selectSelectedRole);
        const oldEntities = yield select(BackofficeSelector.selectEntities);

        if (Object.keys(oldEntities).length === 0) {
            return;
        }

        // TODO Not ideal to get all companies in creation,
        //  but if we are getting with same filters we are using for pagination some migth be missed
        //  due to the multiple admins working on the same dataset
        const entities = yield call(BackofficeApi.getCompaniesInCreationRequest, {
            roles: [selectedRole],
        });

        const updatedEntities = Object.values(oldEntities).map(entity => {
            return entities[entity.companyId] ?? false;
        }).filter(Boolean).reduce((accumulator, current) => {
            accumulator[current.companyId] = current;

            return accumulator;
        }, {});

        yield put(BackofficeActions.storeCompaniesInCreationEntities(updatedEntities));
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
    }
};

const filterCompaniesBEFlow = function* (filter, sort) {
    const selectedRole = yield select(SidebarSelectors.selectSelectedRole);

    try {
        const coaches = filter?.coaches
            ? Object.keys(filter.coaches).filter(coach => filter.coaches[coach]).join(',')
            : null;
        const stage = filter?.stage
            ? Object.keys(filter.stage).filter(stage => filter.stage[stage]).join(',')
            : null;

        const params = {
            offset: filter?.page ? ((filter?.page - 1) * (filter?.perPage ?? PAGE_OPTIONS.PAGE_DEFAULT_SIZE)) : 0,
            limit: filter?.perPage ?? PAGE_OPTIONS.PAGE_DEFAULT_SIZE,
            coaches: filter?.coaches && coaches ? coaches : undefined,
            stage: filter?.stage && stage ? stage : undefined,
            startDate: filter?.startDate ? moment(filter.startDate).format('YYYY-MM-DD') : undefined,
            endDate: filter?.endDate ? moment(filter.endDate).format('YYYY-MM-DD') : undefined,
            quickFilter: filter?.quickFilter,
            text: filter?.text,
            sortColumn: sort ? 'start_date' : undefined,
            sortDirection: sort ? sort.toUpperCase() : undefined,
            roles: [selectedRole],
        };

        let {entities, meta, totalRecords} = yield call(BackofficeApi.getCompaniesInCreationRequest, params);

        const entityArray = Object.values(entities);

        const ids = entityArray.map(dto => dto.companyId);
        entities = entityArray.reduce((accumulator, current) => {
            accumulator[current.companyId] = current;

            return accumulator;
        }, {});

        yield all([
            put(BackofficeActions.storeCompaniesInCreationIds(ids)),
            put(BackofficeActions.storeCompaniesInCreationEntities(entities)),
            put(BackofficeActions.storeShortFilterCount(meta)),
            put(BackofficeActions.storeTotalCount(totalRecords)),
        ]);

        yield put(LoadingActions.setLoading(LoadingTypes.COMPANIES_IN_CREATION_FILTER, false));
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.COMPANIES_IN_CREATION_FILTER, false));
    }
};

const sendReminderFlow = function* ({to, type, subject, message}) {
    try {
        yield call(BackofficeApi.sendReminderRequest, {to, type, subject, message});
        // TODO Implement real indicator that email is sent
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER_SENT, true));
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER_SENT, false));

        yield call(updateCompaniesFlow);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, false));
    }
};

const saveRegistrationStatusFlow = function* ({to, data}) {
    try {
        yield call(BackofficeApi.saveRegistrationPerformed, {to, data});

        Toast.success('genericSuccessSave');

        yield call(updateCompaniesFlow);

        yield put(LoadingActions.setLoading(LoadingTypes.SAVING_REGISTRATION_STATUS, false));

        yield put(UiActions.setActiveModal(ModalsKeys.REGISTRATION_STATUS_MODAL, false));
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        yield put(LoadingActions.setLoading(LoadingTypes.SAVING_REGISTRATION_STATUS, false));
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, false));
        Toast.error('genericError');
    }
};

const getKbisDocumentsFlow = function* ({freelancerId, companyId}) {
    try {
        const files = yield call(BackofficeApi.getKbisDocuments, freelancerId, companyId);
        if (files?.data?.length) {
            yield put(BackofficeActions.storeKbisDocuments(files.data[files.data.length - 1]));
        } else {
            yield put(BackofficeActions.storeKbisDocuments({}));
        }
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const getLegalNoticeDocumentsFlow = function* ({freelancerId, companyId}) {
    try {
        const files = yield call(BackofficeApi.getCompanyRegistrationDocuments, freelancerId, companyId);
        if (files?.data?.length) {
            const legalNoticeDocument = files?.data.find(document => document.doc_type === DOCUMENT_TYPES.LEGAL_NOTICE);
            if (legalNoticeDocument) {
                yield put(BackofficeActions.storeLegalNoticeDocument(legalNoticeDocument));
            } else {
                yield put(BackofficeActions.storeLegalNoticeDocument({}));
            }
        } else {
            yield put(BackofficeActions.storeLegalNoticeDocument({}));
        }
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

export const editRegistrationDateFlow = function* (data) {
    try {
        const {
            freelancerId,
            companyId,
            date,
        } = data;

        yield call(BackofficeApi.editRegistrationDate, freelancerId, companyId, date);

        // Update element in the bo list of items
        const boEntities = yield select(BackofficeSelector.selectEntities);

        const currentItem = boEntities[companyId];


        let newItem;
        if (currentItem.companyStatus === FULL_COMPANY_STATUSES.PENDING_ENABLE_SETUP
        || currentItem.companyStatus === FULL_COMPANY_STATUSES.PENDING_SETUP_START
        || currentItem.companyStatus === FULL_COMPANY_STATUSES.PENDING_DATA_INPUT) {
            newItem = {
                ...currentItem,
                activityStartDate: moment(date, 'DD/MM/YYYY'),
            };
        } else {
            newItem = {
                ...currentItem,
                activityStartDate: moment(date, 'DD/MM/YYYY'),
                companyStatus: FULL_COMPANY_STATUSES.PENDING_INIT_SIGS,
                companyQuickFilter: {...currentItem.companyQuickFilter, pending_registration: false},
                companyStages: {
                    [STAGES.PENDING_CONTRACT]: 'COMPLETED',
                    [STAGES.PENDING_REGISTRATION]: FULL_COMPANY_STATUSES.PENDING_INIT_SIGS,
                    [STAGES.PENDING_CAPITAL_DEPOSIT]: null,
                    [STAGES.PENDING_REGISTRATION_FILE]: null,
                    [STAGES.PENDING_FINAL_DOCUMENTS]: null,
                },
            };
        }
        const newBoEntities = {...boEntities, [companyId]: newItem};

        yield put(BackofficeActions.storeCompaniesInCreationEntities(newBoEntities));

        Toast.success('genericSuccessSave');
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.EDIT_REGISTRATION_DATE, false));
    }
};

const uploadSetupFileFlow = function* ({companyId, data}) {
    try {
        if (data?.files?.length > 0) {
            yield call(uploadDocumentFlow, {
                companyId,
                file: data.files[0],
                type: 'LEGAL_NOTICE',
                category: 'COMPANY_SETUP',
            });
        }
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const startCompanyFlow = function* ({freelancerId, companyId, data}) {
    try {
        if (data?.files?.length > 0) {
            yield call(uploadDocumentFlow, {
                freelancerId,
                companyId,
                file: data.files[0],
                type: 'LEGAL_NOTICE',
                category: 'COMPANY_SETUP',
            });
        }
        const response = yield call(BackofficeApi.startCompany, freelancerId, companyId, data);
        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const companyRegistrationCreateDraftFlow = function* ({freelancerId, companyId}) {
    try {
        const response = yield call(BackofficeApi.companyRegistrationCreateDraft, freelancerId, companyId);
        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const companyRegistsrationRestartFlow = function* ({freelancerId, companyId}) {
    try {
        const response = yield call(BackofficeApi.companyRegistsrationRestart, freelancerId, companyId);
        yield call(handleCompanyResponse, response);
        // Refresh Legal Notice document
        yield put(BackofficeActions.getLegalNoticeDocument(companyId, freelancerId));
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
    }
};

const companyRegistsrationRetryFlow = function* ({freelancerId, companyId, ignoreWarnings}) {
    try {
        const response = yield call(BackofficeApi.companyRegistsrationRetry, freelancerId, companyId, ignoreWarnings);
        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
    }
};

const companyRegistsrationRefreshFlow = function* ({freelancerId, companyId}) {
    try {
        const response = yield call(BackofficeApi.companyRegistsrationRefresh, freelancerId, companyId);
        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.AUTOMATIC_REGISTRATION_REFRESH, false));
    }
};

const companyRegistsrationManualLaunchFlow = function* ({freelancerId, companyId}) {
    try {
        const response = yield call(BackofficeApi.companyRegistrationManualLaunch, freelancerId, companyId);
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));

        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
    }
};

const companyRegistsrationManualValidateFlow = function* ({freelancerId, companyId, inpiIntegrationId}) {
    try {
        const response = yield call(
            BackofficeApi.companyregistrationManualValidate,
            freelancerId,
            companyId,
            inpiIntegrationId,
        );
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));

        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
    }
};

const companyRegistsrationFinalizationFlow = function* ({freelancerId, companyId}) {
    try {
        const response = yield call(BackofficeApi.companyRegistrationFinalization, freelancerId, companyId);
        yield call(handleCompanyResponse, response);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
    }
};

const companyRegistsrationUpdateNoteFlow = function* ({freelancerId, companyId, note}) {
    try {
        yield call(BackofficeApi.updateEnterpriseInformationNote, freelancerId, companyId, note);
    } catch (error) {
        Debug.error('backoffice', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const handleCompanyResponse = function* (response) {
    yield put(storeCompany(response));

    const entities = yield select(BackofficeSelector.selectEntities);

    yield put(BackofficeActions.storeCompaniesInCreationEntities({
        ...entities,
        [response.id]: {
            ...entities[response.id],
            status: response.status,
        },
    }));

    if (response.status !== entities[response.id].status) {
        // TODO Maybe I should not call it always?
        yield call(updateCompaniesFlow);
    }
};

const globalDataExportFlow = function* () {
    try {
        const selectedRole = yield select(SidebarSelectors.selectSelectedRole);
        yield put(LoadingActions.setLoading(LoadingTypes.GLOBAL_DATA_EXPORT, true));
        const csvString = yield call(UserApi.getGlobalDataExport, selectedRole);
        const blob = new Blob([csvString], {type: 'text/csv'});
        window.saveAs(blob, 'freelancer-global-data.csv');
        yield put(LoadingActions.setLoading(LoadingTypes.GLOBAL_DATA_EXPORT, false));
    } catch (error) {
        Debug.error('Backoffice globalDataExportFlow', 'Error: ', {error});
        Toast.error('genericError');
        yield put(LoadingActions.setLoading(LoadingTypes.GLOBAL_DATA_EXPORT, false));
    }
};

// Workers
export const filterCompaniesWorker = function* ({payload}) {
    const {filter, sort} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.COMPANIES_IN_CREATION_FILTER, true));

    yield call(filterCompaniesBEFlow, filter, sort);

    yield put(LoadingActions.setLoading(LoadingTypes.COMPANIES_IN_CREATION_FILTER, false));
};

export const sendReminderWorker = function* ({payload}) {
    const {to, type, subject, message} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, true));

    yield call(sendReminderFlow, {to, type, subject, message});

    yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, false));
};

export const saveRegistrationStatusWorker = function* ({payload}) {
    const {to, status, data} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.SAVING_REGISTRATION_STATUS, true));

    yield call(saveRegistrationStatusFlow, {status, data, to});

    yield put(LoadingActions.setLoading(LoadingTypes.SAVING_REGISTRATION_STATUS, false));
};

const getKbisDocumentsWorker = function* ({payload}) {
    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(getKbisDocumentsFlow, {
        freelancerId,
        companyId,
    });
};

const getLegalNoticeDocumentsWorker = function* ({payload}) {
    const {
        freelancerId,
        companyId,
    } = payload;
    yield call(getLegalNoticeDocumentsFlow, {
        freelancerId,
        companyId,
    });
};

const editRegistrationDateWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.EDIT_REGISTRATION_DATE, true));

    const {
        freelancerId,
        companyId,
        date,
    } = payload;

    yield call(editRegistrationDateFlow, {
        freelancerId,
        companyId,
        date,
    });

    yield put(UiActions.setActiveModal(
        ModalsKeys.BACKOFFICE_EDIT_DATE, false,
    ));

    yield put(LoadingActions.setLoading(LoadingTypes.EDIT_REGISTRATION_DATE, false));
};

const uploadSetupFileWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_DOCUMENT, true));

    const {
        companyId,
        data,
    } = payload;

    yield call(uploadSetupFileFlow, {
        companyId,
        data,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_DOCUMENT, false));
};

const startCompanyWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
        data,
    } = payload;

    yield call(startCompanyFlow, {
        freelancerId,
        companyId,
        data,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistrationCreateDraftWroker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(companyRegistrationCreateDraftFlow, {
        freelancerId,
        companyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationRestartWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(companyRegistsrationRestartFlow, {
        freelancerId,
        companyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationRetryWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
        ignoreWarnings,
    } = payload;

    yield call(companyRegistsrationRetryFlow, {
        freelancerId,
        companyId,
        ignoreWarnings,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationRefreshWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.AUTOMATIC_REGISTRATION_REFRESH, true));

    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(companyRegistsrationRefreshFlow, {
        freelancerId,
        companyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.AUTOMATIC_REGISTRATION_REFRESH, false));
};

const companyRegistsrationManualLaunchWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(companyRegistsrationManualLaunchFlow, {
        freelancerId,
        companyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationManualValidateWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
        inpiIntegrationId,
    } = payload;

    yield call(companyRegistsrationManualValidateFlow, {
        freelancerId,
        companyId,
        inpiIntegrationId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationFinalizationWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    const {
        freelancerId,
        companyId,
    } = payload;

    yield call(companyRegistsrationFinalizationFlow, {
        freelancerId,
        companyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const companyRegistsrationUpdateNoteWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_NOTE_UPDATE, true));

    const {
        freelancerId,
        companyId,
        note,
    } = payload;

    yield call(companyRegistsrationUpdateNoteFlow, {
        freelancerId,
        companyId,
        note,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.BO_NOTE_UPDATE, false));
};

const companyPoolingWorker = function* ({payload}) {
    yield call(getCompanySaga, {payload});
};

const globalDataExportWorker = function* () {
    const isAlreadyInProgress = yield select(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GLOBAL_DATA_EXPORT),
    );
    if (isAlreadyInProgress) {
        return;
    }
    yield call(globalDataExportFlow);
};

export const watchBackofficeSaga = function* () {
    yield all([
        takeLatest(BackofficeActionTypes.FILTER_COMPANIES, filterCompaniesWorker),
        takeEvery(BackofficeActionTypes.SEND_REMINDER, sendReminderWorker),
        takeEvery(BackofficeActionTypes.SAVE_REGISTRATION_STATUS, saveRegistrationStatusWorker),
        takeLatest(BackofficeActionTypes.GET_KBIS_DOCUMENTS, getKbisDocumentsWorker),
        takeLatest(BackofficeActionTypes.GET_LEGAL_NOTICE_DOCUMENT, getLegalNoticeDocumentsWorker),
        takeEvery(BackofficeActionTypes.EDIT_REGISTRATION_DATE, editRegistrationDateWorker),

        takeEvery(BackofficeActionTypes.UPLOAD_COMPANY_SETUP_FILE, uploadSetupFileWorker),
        takeEvery(BackofficeActionTypes.START_COMPANY_REGISTRATION, startCompanyWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_START_POLLING, companyPoolingWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_CREATE_DRAFT, companyRegistrationCreateDraftWroker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_RESTART, companyRegistsrationRestartWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_RETRY, companyRegistsrationRetryWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_REFRESH, companyRegistsrationRefreshWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_MANUAL_LAUNCH, companyRegistsrationManualLaunchWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_MANUAL_VALIDATE, companyRegistsrationManualValidateWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_FINALIZATION, companyRegistsrationFinalizationWorker),
        takeEvery(BackofficeActionTypes.COMPANY_REGISTRATION_UPDATE_NOTE, companyRegistsrationUpdateNoteWorker),

        takeEvery(BackofficeActionTypes.GLOBAL_DATA_EXPORT, globalDataExportWorker),
    ]);
};
