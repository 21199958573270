export const INCOME_TAX_PARAMETERS = {
    ADULTS: 'ADULTS',
    TAX_SHARES: 'TAX_SHARES',
    OTHER_INCOME: 'OTHER_INCOME',
    JOINT_INCOME: 'JOINT_INCOME',
};

export const INCOME_TAX_MOBILE_SIZE = '767px';

export const IncomeTaxParamSteps = {
    MyHome: 'MyHome',
    MyActivity: 'MyActivity',
    OwnIncome: 'OwnIncome',
    SpouseIncome: 'SpouseIncome',
};

export const MyHomeParams = {
    numberOfAdults: 'numberOfAdults',
    fiscalParts: 'fiscalParts',
};

export const MyActivityParams = {
    dailyRate: 'dailyRate',
    daysWorked: 'daysWorked',
    caForecast: 'caForecast', // calculated locally
    meanMonthlyExpenses: 'meanMonthlyExpenses',
    annualForecast: 'annualForecast', // calculated locally
    estimatedBNC: 'estimatedBNC', // calculated locally
};

export const OwnIncomeParams = {
    ownWageIncome: 'ownWageIncome',
    ownFranceTravail: 'ownFranceTravail',
    otherIncome: 'otherIncome', // calculated locally
    ownManagerIncome: 'ownManagerIncome',
    ownMicroenterpriseIncomeBIC: 'ownMicroenterpriseIncomeBIC',
    ownMicroenterpriseIncomeBNC: 'ownMicroenterpriseIncomeBNC',
    ownMicroenterpriseIncomeBICPurchaseSale: 'ownMicroenterpriseIncomeBICPurchaseSale',
    ownPER: 'ownPER',
};

export const SpouseIncomeParams = {
    spouseWageIncome: 'spouseWageIncome',
    spouseFranceTravail: 'spouseFranceTravail',
    spouseOtherIncome: 'spouseOtherIncome', // calculated locally
    spouseManagerIncome: 'spouseManagerIncome',
    spouseMicroenterpriseIncomeBIC: 'spouseMicroenterpriseIncomeBIC',
    spouseMicroenterpriseIncomeBNC: 'spouseMicroenterpriseIncomeBNC',
    spouseMicroenterpriseIncomeBICPurchaseSale: 'spouseMicroenterpriseIncomeBICPurchaseSale',
};

export const incomeTaxFormDefaultValues = {
    /* My Home */
    [MyHomeParams.numberOfAdults]: 1,
    [MyHomeParams.fiscalParts]: 1,

    /* My Activity */
    [MyActivityParams.dailyRate]: 0,
    // TODO: Calculate default value based on info in epic
    [MyActivityParams.daysWorked]: 218,
    [MyActivityParams.meanMonthlyExpenses]: 1100,

    /* My Own Income */
    [OwnIncomeParams.ownWageIncome]: 0,
    [OwnIncomeParams.ownFranceTravail]: 0,
    [OwnIncomeParams.ownManagerIncome]: 0,
    [OwnIncomeParams.ownMicroenterpriseIncomeBIC]: 0,
    [OwnIncomeParams.ownMicroenterpriseIncomeBNC]: 0,
    [OwnIncomeParams.ownMicroenterpriseIncomeBICPurchaseSale]: 0,
    [OwnIncomeParams.ownPER]: 0,

    /* My Spouse */
    [SpouseIncomeParams.spouseWageIncome]: 0,
    [SpouseIncomeParams.spouseFranceTravail]: 0,
    [SpouseIncomeParams.spouseManagerIncome]: 0,
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBIC]: 0,
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBNC]: 0,
    [SpouseIncomeParams.spouseMicroenterpriseIncomeBICPurchaseSale]: 0,
};
